import { Ref, ref } from 'vue';
import { useField } from 'vee-validate';

type DateNames = 'start' | 'end';

type Range = {
  [key in DateNames]: Date;
};

type FieldType = Range | string | number;

interface Fields {
  [key: string]: FieldType;
}

export interface UseFormFields {
  form: Ref<Fields>;
}

export const useFormFields = (fields: string[]): UseFormFields => {
  const form = ref({});

  fields.forEach((fieldName) => {
    const { errorMessage, value } = useField(fieldName);
    Object.assign(form.value, { [`${fieldName}Err`]: errorMessage, [fieldName]: value });
  });

  return { form };
};
